.log {
  padding: 20px;
}

.log-controls {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.log-search {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 200px;
}

.log-sort {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: black;
  gap: 4px;
  background-color: #8ED9F8;
}

.log-sort:hover {
  background-color: #8ED9F8;
}

.log-sort:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sort-icon {
  opacity: 0.7;
}

.log-list {
  list-style: none;
  padding: 0;
}

.log-entry {
  padding: 15px;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
}

.log-entry p {
  margin: 5px 0;
}

.log-entry a {
  color: #0066cc;
  text-decoration: none;
}

.log-entry a:hover {
  text-decoration: underline;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}

.page-info {
  font-size: 14px;
  color: black;
  padding: 8px;
}