.custom-profile {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.custom-profile-section {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.custom-image-upload {
  margin-bottom: 20px;
  position: relative;
}

.custom-image-upload-label {
  display: inline-block;
  cursor: pointer;
}

.custom-add-image-placeholder {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ddd;
  border-radius: 10px;
  color: #666;
  font-size: 18px;
  text-align: center;
}

.custom-profile-picture {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}

.custom-message-section {
  width: 75%;
  margin-bottom: 20px;
}

.custom-message-section label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.custom-message-section input {
  width: 75%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  resize: vertical;
}

.custom-message-section textarea {
  width: 75%;
  height: 100px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  resize: vertical;
}

.custom-save-button {
  width: 30%;
  overflow: hidden;
  padding: 15px 20px;
  margin-right: 20px;
  background-color: #5cb85c;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.custom-save-button:hover {
  background-color: #4cae4c;
}

.custom-subscribe-button {
  width: 20%;
  overflow: hidden;
  padding: 15px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.custom-subscribe-button:hover {
  background-color: #0056b3;
}

.custom-unsubscribe-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.custom-unsubscribe-button:hover {
  background-color: #a71d2a;
}

.profile-buttons {
  display: flex;
}
