.notifications {
  margin: 0 auto;
  padding: 20px;
}

.notification-section {
  margin-bottom: 20px;
}

.notification-section h2 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #333;
}

.notification-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.notification-item select,
.notification-item input,
.notification-item textarea {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.notification-item textarea {
  height: 60px;
  resize: vertical;
}

.notification-item button {
  padding: 8px 12px;
  background-color: #ff4d4d;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
}

.notification-item button:hover {
  background-color: #ff3333;
}

.follow-up-email {
  margin-top: 40px;
}

.follow-up-email h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.follow-up-email .notification-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.follow-up-email select,
.follow-up-email input,
.follow-up-email textarea {
  width: 50%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.follow-up-email textarea {
  height: 100px;
  resize: vertical;
}

.follow-up-email button {
  padding: 10px 15px;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
}

.follow-up-email button:hover {
  background-color: #218838;
}

button {
  padding: 10px 15px;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #218838;
}

/* Keep your existing styles */

/* Keep your existing styles */

@media (max-width: 1090px) {
  .notification-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    background: #f9f9f9;
    border-radius: 4px;
    margin-bottom: 15px;
  }

  .notification-item select,
  .notification-item input,
  .notification-item button {
    margin: 5px 0;
    width: 100%;
  }

  .notification-item textarea {
    width: 100%;
  }
}

@media (max-width: 840px) {
  .notification-item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  /* Type selector (email/sms) takes first column */
  .notification-item select:first-of-type {
    grid-column: 1;
    width: 100%;
  }

  /* Value input (email/phone) takes second column */
  .notification-item input:first-of-type {
    grid-column: 2;
    width: 100%;
  }

  /* For both incoming leads and calendar notifications */
  .notification-item button,
  .notification-item input:not(:first-of-type),
  .notification-item select:not(:first-of-type) {
    grid-column: 1 / -1;
    width: 100%;
  }
}