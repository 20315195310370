/* src/components/Dashboard.css */
.dashboard {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  
  .dashboard table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .dashboard th, .dashboard td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    height: 25px;
  }
  
  .dashboard th {
    background-color: #f4f4f4;
  }
  
  .dashboard tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .dashboard tr:nth-child(odd) {
    background-color: #ffffff;
  }
  