/* src/components/Agents.css */
.agents {
  padding: 20px;
}

.toolbar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.toolbar button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.toolbar button:hover {
  background-color: #0056b3;
}

.toolbar button svg {
  margin-right: 5px;
}

.agents table {
  width: 100%;
  border-collapse: collapse;
}

.agents th,
.agents td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.agents th {
  background-color: #f4f4f4;
}

.agents tr.even {
  background-color: #f9f9f9;
}

.agents tr.odd {
  background-color: #ffffff;
}

.agents tr:hover {
  background-color: #ddd;
  cursor: pointer;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  padding: 20px;
  box-shadow: 24px;
  border-radius: 8px;
}

.right-sidebar {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: #f4f4f4;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease;
  overflow-y: auto;
}

.right-sidebar.open {
  right: 0;
}

.right-sidebar .content {
  padding: 10px; /* Reduced padding */
  overflow-y: auto;
  height: 100%;
  margin-top: 60px;
}

.right-sidebar .content h2,
.right-sidebar .content p,
.right-sidebar .content label,
.right-sidebar .content ul {
  margin: 5px 0; /* Reduced margin */
}

.right-sidebar .content h3 {
  margin: 10px 0 5px 0; /* Reduced margin above and below h3 headings */
}

.right-sidebar .content button {
  margin-bottom: 10px;
}

.log-entry {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px; /* Reduced padding */
  margin: 5px 0; /* Reduced margin */
  list-style-type: none;
}

.assigned-leads-list {
  padding: 0;
  list-style: none;
}

.log-list li {
  padding: 5px; /* Reduced padding */
  border-bottom: 1px solid #ccc;
}

.log-list li:last-child {
  border-bottom: none;
}

.log-note {
  background-color: #ffffcc;
  color: #4f5123;
}

.log-note path {
  color: #babd55;
}

.log-update {
  background-color: #f0f0f0;
  color: #535252;
}

.log-update path {
  color: grey;
}

.log-event {
  background-color: #cce5ff;
  color: #46587d;
}

.log-icon {
  vertical-align: middle;
  margin-right: 5px;
}

.assign-agent {
  margin-bottom: 10px;
}

.add-note {
  color: white !important;
}

.edit-button {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.delete-button {
  margin-bottom: 10px !important;
}

.add-note label {
  color: white !important;
}

.add-note input {
  color: white !important;
  border-color: white;
}

.add-note fieldset {
  border-color: white;
}

.assigned-leads-list li {
  cursor: pointer;
  padding: 8px;
  transition: background-color 0.2s;
  border-radius: 4px;
}

.assigned-leads-list li:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
